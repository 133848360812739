import { LinkCategory } from '@/types/Creator.type'
import { get, patch, post, put, putForm } from './base'

export const getCreatorSnsLinks = async () => {
  const url = '/creators/self/sns_links'
  return await get(url)
}

export const putCreatorSnsLinks = async (sns_links: LinkCategory[]) => {
  const url = '/creators/self/sns_links'
  return await post(url, {
    sns_links: sns_links,
  })
}

export const patchCreatorSnsLinks = async (sns_links: LinkCategory[]) => {
  const url = '/creators/self/sns_links'
  return await patch(url, {
    sns_links: sns_links,
  })
}

export const moveCreatorSnsLinks = async (value: string[]) => {
  const url = '/creators/self/sns_links/move_link'
  return await put(url, {
    sns_links: value,
  })
}

export const getCreatorProfile = async () => {
  const url = '/creators/self/profile'
  return await get(url)
}

export const setCreatorSnsColorMode = async (mode: string) => {
  const url = '/creators/self/profile/sns_link_color'
  return await put(url, {
    sns_link_color: mode,
  })
}

export const getAccountIdentity = async (uid: string) => {
  const url = '/creators/account_identity?uid=' + uid
  return await get(url)
}

export const updateCover = async (form: FormData, resourceType: string, isUpdate: boolean) => {
  const url = `/creators/self/profile/covers/${resourceType}`
  return isUpdate
    ? await putForm(url, form, { auth: true })
    : await post(url, form, { 'Content-Type': 'multipart/form-data' })
}
