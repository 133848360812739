import React, { ReactNode } from 'react'
import styled from '@emotion/styled'
import { Colors } from '@/constants/styles/color'
import styles from '../style.module.scss'

const StyledContainerMask = styled.div<{ windowWidth: number; zIndex?: number }>`
  width: 100%;
  max-width: 480px;
  background: ${Colors.BLACK};
  position: fixed;
  top: 0px;
  height: 100vh;
  opacity: 0.5;
  z-index: ${p => (p.zIndex ? p.zIndex : 10000)};
  left: ${p => (p.windowWidth > 480 ? 'calc((100vw - 480px) / 2)' : '0')};
`

const StyledContainer = styled.div<{
  windowWidth: number
  zIndex?: number
  top?: number
  margin?: string
}>`
  width: 100%;
  max-width: 480px;
  position: fixed;
  top: ${p => (p.top ? `${p.top}px` : '56px')};
  height: calc(100vh - ${p => (p.top ? `${p.top}px` : '56px')});
  border-radius: 20px 20px 0px 0px;
  z-index: ${p => (p.zIndex ? p.zIndex + 1 : 10001)};
  left: ${p => (p.windowWidth > 480 ? 'calc((100vw - 480px) / 2)' : '0')};
`

const StyledInnerWrapper = styled.div<{
  margin?: string
}>`
  position: relative;
  max-width: 480px;
  background: ${Colors.WHITE};
  height: 100%;
  margin: ${p => (p.margin ? p.margin : 'unset')};
  border-radius: 20px 20px 0px 0px;
`

const StyledHeaderWrapper = styled.div`
  display: flex;
  justify-content: center;
  height: 48px;
  border-bottom: 1px solid ${Colors.VERY_LIGHT_GRAY};
  align-items: center;
  font-size: 14px;
`

const StyledDialogBodyWrapper = styled.div<{ height?: string }>`
  height: ${props => props.height || '100%'};
  align-items: center;
  text-align: initial;
  overflow: auto;
`

type Props = {
  header: ReactNode
  content: ReactNode
  onClose?: () => void
  zIndex?: number
  top?: number
  margin?: string
  bodyHeight?: string
}

const HalfModal = ({ header, content, onClose, zIndex, top, margin, bodyHeight }: Props) => {
  const windowWidth = window.innerWidth
  return (
    <>
      <StyledContainerMask onClick={onClose} windowWidth={windowWidth} zIndex={zIndex} />
      <StyledContainer className={styles.popup} windowWidth={windowWidth} zIndex={zIndex} top={top}>
        <StyledInnerWrapper margin={margin}>
          <StyledHeaderWrapper>{header}</StyledHeaderWrapper>
          <StyledDialogBodyWrapper height={bodyHeight}>{content}</StyledDialogBodyWrapper>
        </StyledInnerWrapper>
      </StyledContainer>
    </>
  )
}

export default HalfModal
