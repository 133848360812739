import getConfig from 'next/config'
import { UnreadMsg } from '@/types/Purchase'
import { get, post } from './base'

const { publicRuntimeConfig } = getConfig()

export const getTeenagerStatus = async (items: any) => {
  const url = `${publicRuntimeConfig.PAYMENT_URL}/teenager`
  return await post(url, { items }, {}, { auth: true })
}

export const getOrder = async (params: any) => {
  const url = `${publicRuntimeConfig.PAYMENT_URL}/payment/order`
  return await post(url, params, {}, { auth: true })
}

export const requestCvsPurchase = async (params: any) => {
  const url = `${publicRuntimeConfig.PAYMENT_URL}/payment/cvs`
  return await post(url, params, {}, { auth: true })
}

export const getCvsPurchaseInfo = async (params: any) => {
  const url = `${publicRuntimeConfig.PAYMENT_URL}/payment/cvs/get`
  return await post(url, params, {}, { auth: true })
}

export const getAddressInfo = async () => {
  const url = `${publicRuntimeConfig.PAYMENT_URL}/address`
  return await get(url)
}

export const createAddressInfo = async (params: any) => {
  const url = `${publicRuntimeConfig.PAYMENT_URL}/address/create`
  return await post(url, params, {}, { auth: true })
}

export const cancelCvsPurchase = async (params: any) => {
  const url = `${publicRuntimeConfig.PAYMENT_URL}/payment/cvs/cancel`
  return await post(url, params, {}, { auth: true })
}

export const followCreator = async (uid: string) => {
  const url = `${publicRuntimeConfig.PAYMENT_URL}/seller/follow`
  return await post(
    url,
    {
      followed_creator_id: uid,
    },
    {},
    { auth: true },
  )
}

export const getSellerDepositAccount = async () => {
  const url = `${publicRuntimeConfig.PAYMENT_URL}/seller/account/deposit/get`
  return await get(url)
}
export const getCreditAccountData = async () => {
  const url = `${publicRuntimeConfig.PAYMENT_URL}/seller/account/transfer/account_data`
  return await get(url)
}

export const getCreditAccountRegister = async () => {
  const url = `${publicRuntimeConfig.PAYMENT_URL}/seller/account/transfer/bank_id`
  return await get(url)
}

export const unfollowCreator = async (uid: string) => {
  const url = `${publicRuntimeConfig.PAYMENT_URL}/seller/follow/delete`
  return await post(
    url,
    {
      followed_creator_id: uid,
    },
    {},
    { auth: true },
  )
}

export const getFollowStatus = async (ids: string[]) => {
  const url = `${publicRuntimeConfig.PAYMENT_URL}/seller/follow/check`
  return await post(
    url,
    {
      followed_creator_ids: ids,
    },
    {},
    { auth: true },
  )
}

export const updateCreditCard = async (params: any) => {
  const url = `${publicRuntimeConfig.PAYMENT_URL}/card/update`
  return await post(url, params, {}, { auth: true })
}

export const getSellerAppItemPrices = async (
  sellerUserId: string,
  sellerAppId: string,
  sellerAppItemId: string,
) => {
  const url = `${publicRuntimeConfig.PAYMENT_URL}/user/seller/${sellerUserId}/apps/${sellerAppId}/items/${sellerAppItemId}/prices`
  return await get(url, {}, { auth: false })
}

export const getDailyLeftTip = async (sellerAppId: number) => {
  const url = `${publicRuntimeConfig.PAYMENT_URL}/tips/daily_total`
  return await get(url, { params: { seller_app_id: sellerAppId } }, { auth: true })
}

export const payWithSavedCard = async (data: any) => {
  const url = `${publicRuntimeConfig.PAYMENT_URL}/payment/saved_card`
  return await post(url, data, {}, { auth: true })
}

export const payWithSavedCardDev = async (data: any) => {
  const url = `${publicRuntimeConfig.PAYMENT_URL}/developer/payment`
  return await post(url, data, {}, { auth: true })
}

export const googlePay = async (data: any) => {
  const url = `${publicRuntimeConfig.PAYMENT_URL}/payment/google`
  return await post(url, data, {}, { auth: true })
}

export const getApplePaymentToken = async (data: any) => {
  const url = `${publicRuntimeConfig.PAYMENT_URL}/payment/apple/token`
  return await post(url, data, {}, { auth: true })
}

export const applePay = async (data: any) => {
  const url = `${publicRuntimeConfig.PAYMENT_URL}/payment/apple`
  return await post(url, data, {}, { auth: true })
}

export const getSellerApps = async (sellerUserId: string): Promise<MiniAppInfo[]> => {
  const url = `${publicRuntimeConfig.PAYMENT_URL}/user/seller/${sellerUserId}/apps`
  return await get(url, {}, { auth: false })
}

export const getUnreadMsgs = async (): Promise<UnreadMsg> => {
  const url = `${publicRuntimeConfig.PAYMENT_URL}/seller/letters/unread`
  return await get(url, {}, { auth: true })
}
