import { get, post } from './base'

export const getMiniAppInfo = async (creator_id: string) => {
  const url = `/creators/@${creator_id}/mini_app?from=miniapp`
  return await get(url, {}, { auth: false })
}

export const getAppInfo = async (appName: string, sellerAppKey: string, creator_uid: string) => {
  const url = `/app`
  return await post(
    url,
    {
      app_name: appName,
      seller_app_key: sellerAppKey,
      creator_uid: creator_uid,
    },
    {},
    { auth: false },
  )
}

export const getSellerAppItemInfo = async (sellerAppItemId: string) => {
  const url = `/app_item`
  return await post(
    url,
    {
      seller_app_item_id: sellerAppItemId,
    },
    {},
    { auth: false },
  )
}

export const getAppOldInfo = async (sellerAppId: string) => {
  const url = `/app/old_url`
  return await post(
    url,
    {
      seller_app_id: sellerAppId,
    },
    {},
    { auth: false },
  )
}
