import React, { FC, ReactNode, useCallback, useEffect, useRef, useState } from 'react'

interface InfiniteScrollContainerProps {
  children: ReactNode
  fetchMore: () => void
  isRequest?: boolean
}

export const InfiniteScrollContainer: FC<InfiniteScrollContainerProps> = ({
  children,
  fetchMore,
  isRequest = true,
}) => {
  const bottomBoundaryRef = useRef(null)
  const [needFetchMore, setNeedFetchMore] = useState(false)

  const scrollObserver = useCallback(
    (node: any) => {
      new IntersectionObserver(entries => {
        entries.forEach(en => {
          if (en.isIntersecting && isRequest) {
            setNeedFetchMore(true)
          }
        })
      }).observe(node)
    },
    [fetchMore],
  )

  useEffect(() => {
    if (bottomBoundaryRef.current) {
      scrollObserver(bottomBoundaryRef.current)
    }
  }, [scrollObserver, bottomBoundaryRef])

  useEffect(() => {
    if (needFetchMore) {
      fetchMore()
      setNeedFetchMore(false)
    }
  }, [needFetchMore, fetchMore, setNeedFetchMore])

  return (
    <>
      {children}
      <div ref={bottomBoundaryRef} />
    </>
  )
}
